<template>
    <div>
        <div class="d-flex justify-center" style="margin-top: 15px;">
            <v-chip outlined @click="returnPreviousPeriod()"><v-icon>fas fa-arrow-left</v-icon></v-chip>
            <v-chip outlined>{{period}}</v-chip>
            <v-chip outlined @click="returnNextPeriod()"><v-icon>fas fa-arrow-right</v-icon></v-chip>
        </div>
        <v-container grid-list-md>
            <v-layout row wrap>
                <widgetContainer v-for="item in data" v-bind:widgetData="item" v-bind:period="period"></widgetContainer>
            </v-layout>
        </v-container>
    </div>
</template>

<script>

    import { dateManager } from '../../../javascript/dateManager'

    export default {
        components: {
            widgetContainer: () => import('../widgetContainer.vue')
        },
        data() {
            return{
                period: '',
                data: [
                    {
                        title: 'Category Totals',
                        size: 4,
                        link: './incomeWidget/iWMComponents/incomeCategoryTotals.vue'
                    },
                    {
                        title: 'Items By Category',
                        size: 4,
                        link: './incomeWidget/iWMComponents/incomeItemsByCategory.vue'
                    }]
            }
        },
        methods: {
            returnPeriod() {
                this.period = dateManager.returnPeriodRange(
                    this.$store.state.Settings
                    , new Date())
            },
            returnNextPeriod() {
                this.period = dateManager.returnNextPeriodRange(
                    this.$store.state.Settings
                    , this.period)
            },
            returnPreviousPeriod() {
                this.period = dateManager.returnPreviousPeriodRange(
                    this.$store.state.Settings
                    , this.period)
            }
        },
        beforeMount() {
            this.returnPeriod()
        }
    }
</script>